import Cookies from "js-cookie"
const TokenKey = "Admin-Token"
/**
 * 获取token
 */
export const getToken = () => {
  return Cookies.get(TokenKey)
}
/**
 * 设置token
 * @param token token值
 */
export const setToken = (token: string) => {
  return Cookies.set(TokenKey, token)
}
/**
 * 清除token
 */
export const removeToken = () => {
  return Cookies.remove(TokenKey)
}
/**
 * 获取当前用户id
 */
export const getUserID = () => {
  return Cookies.get("user_id")
}
/**
 * 存储用户id
 * @param user_id
 */
export const setUserID = (user_id: string) => {
  return Cookies.set("user_id", user_id)
}
/**
 * 清除当前登录用户id
 */
export const removeUserID = () => {
  return Cookies.remove("user_id")
}

/**
 * 获取用户名
 */
export const getUserName = () => {
  return Cookies.get("user_name")
}
/**
 * 存储用户名字
 * @param user_id
 */
export const setUserName = (user_name: string) => {
  return Cookies.set("user_name", user_name)
}
/**
 * 清除当前登录用户名
 */
export const removeUserName = () => {
  return Cookies.remove("user_name")
}

/**
 * 获取用户头像
 */
export const getAvatar = () => {
  return Cookies.get("user_avatar")
}
/**
 * 存储用户头像
 * @param user_id
 */
export const setUserAvatar = (user_avatar: string) => {
  return Cookies.set("user_avatar", user_avatar)
}
/**
 * 清除当前登录用户头像
 */
export const removeUserAvatar = () => {
  return Cookies.remove("user_avatar")
}
