import { type RouteRecordRaw, createRouter } from "vue-router"
import { history, flatMultiLevelRoutes } from "./helper"
import routeSettings from "@/config/route"

const Layouts = () => import("@oa/layouts/index.vue")

/**
 * 常驻路由
 * 除了 redirect/403/404/login 等隐藏页面，其他页面建议设置 Name 属性
 */

export const constantRoutes: RouteRecordRaw[] = [
  {
    path: "/redirect",
    component: Layouts,
    meta: {
      hidden: true
    },
    children: [
      {
        path: ":path(.*)",
        component: () => import("@oa/views/redirect/index.vue")
      }
    ]
  },
  {
    path: "/403",
    component: () => import("@oa/views/error-page/403.vue"),
    meta: {
      hidden: true
    }
  },
  {
    path: "/404",
    component: () => import("@oa/views/error-page/404.vue"),
    meta: {
      hidden: true
    },
    alias: "/:pathMatch(.*)*"
  },
  {
    path: "/login",
    component: () => import("@oa/views/login/index.vue"),
    meta: {
      hidden: true
    }
  },
  {
    path: "/",
    component: Layouts,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        component: () => import("@oa/views/dashboard/index.vue"),
        name: "Dashboard",
        meta: {
          title: "首页",
          svgIcon: "dashboard",
          affix: true
        }
      }
    ]
  },
  {
    name: "other",
    path: "/other",
    component: Layouts,
    meta: {
      title: "开票管理",
      icon: "iconfont el-icon-invoice-management",
      hidden: true
    },
    children: [
      {
        name: "invoiceInfo",
        path: "/invoiceInfo",
        alias: ["invoiceInfo/:id?/:listType?/:childTitle?", ""],
        component: () => import("@oa/views/oa_invoice/invoiceInfo.vue"),
        meta: {
          title: "开票信息",
          icon: "iconfont el-icon-menu-my-invoice",
          hidden: true,
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      }
    ]
  }
]

/**
 * 动态路由
 * 用来放置有权限 (Roles 属性) 的路由
 * 必须带有 Name 属性
 */
export const dynamicRoutes: RouteRecordRaw[] = []
export const dynamicRoutes1: RouteRecordRaw[] = [
  {
    name: "OAEmployee",
    path: "/oa_employee",
    component: Layouts,
    redirect: "/oa_employee",
    meta: {
      title: "工作管理",
      icon: "iconfont el-icon-work-management",
      alwaysShow: true
    },

    children: [
      {
        name: "WorkLog",
        path: "oa_employee/work_log",
        component: () => import("@oa/views/oa_employee/work_log.vue"),
        meta: {
          title: "工作日志",
          icon: "iconfont el-icon-work-log",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },
      {
        name: "WorkHourList",
        path: "work_log_list",
        component: () => import("@oa/views/oa_employee/work_log_list.vue"),
        meta: {
          title: "工时详情",
          icon: "iconfont el-icon-work-hour-list",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },
      {
        name: "WorkHourApproval",
        path: "work_hour_approval",
        component: () => import("@oa/views/oa_employee/work_hour_approval.vue"),
        meta: {
          title: "工时审批",
          icon: "iconfont el-icon-work-hour-approval",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      }
    ]
  },

  {
    name: "project_manage",
    path: "/oa_project",
    component: Layouts,
    meta: {
      title: "项目管理",
      icon: "iconfont el-icon-project-management",
      alwaysShow: true
    },

    children: [
      {
        name: "projectInfo",
        path: "oa_project/projectInfo",
        alias: ["oa_project/projectInfo/:id?/:childTitle?/:arg?"],
        component: () => import("@oa/views/oa_project/oaProjectInfo.vue"),
        meta: {
          title: "项目信息",
          icon: "iconfont el-icon-project-registration",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },
      {
        name: "projectList",
        path: "oa_project/projectList",
        component: () => import("@oa/views/oa_project/projectList.vue"),
        meta: {
          title: "项目列表",
          icon: "iconfont el-icon-menu-project-list",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },
      {
        name: "myProjectList",
        path: "oa_project/myProjectList",
        component: () => import("@oa/views/oa_project/myProjectList.vue"),
        meta: {
          title: "我的项目",
          icon: "iconfont el-icon-menu-my-project",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },
      {
        name: "uncollectedList",
        path: "uncollectedList",
        component: () => import("@oa/views/oa_invoice/uncollectedList.vue"),
        meta: {
          title: "未回款清单",
          icon: "iconfont el-icon-menu-nopay-list",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },
      {
        name: "projectMember",
        path: "oa_project/projectMember",
        component: () => import("@oa/views/oa_project/projectMember.vue"),
        meta: {
          title: "项目成员",
          icon: "iconfont el-icon-project-member-management",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      }
    ]
  },

  {
    name: "expanse_manage",
    path: "/oa_expanse",
    component: Layouts,
    meta: {
      title: "账目管理",
      icon: "iconfont el-icon-financial-management",
      alwaysShow: true,
      // 是否缓存该路由页面
      // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
      keepAlive: true
    },

    children: [
      {
        name: "expanseInfo",
        path: "oa_expanse/expanseInfo",
        alias: ["oa_expanse/expanseInfo/:id?/:listType?/:childTitle?", ""],
        component: () => import("@oa/views/oa_expanse/expanseInfo.vue"),
        meta: {
          title: "报销申请",
          icon: "iconfont el-icon-expense-registration",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },
      {
        name: "myExpanseList",
        path: "oa_expanse/myExpanseList",
        component: () => import("@oa/views/oa_expanse/myExpanseList.vue"),
        meta: {
          title: "我的报销",
          icon: "iconfont el-icon-menu-my-expanse",
          listType: "MY",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },
      {
        name: "FinanceExpanseList",
        path: "FinanceExpanseList",
        component: () => import("@oa/views/oa_expanse/myExpanseList.vue"),
        meta: {
          title: "财务账目",
          icon: "iconfont el-icon-menu-my-expanse",
          listType: "FINANCE",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      }
    ]
  },

  {
    name: "invoice_manage",
    path: "/oa_invoice",
    component: Layouts,
    meta: {
      title: "开票管理",
      icon: "iconfont el-icon-invoice-management",
      alwaysShow: true
    },

    children: [
      {
        name: "invoiceInfo",
        path: "/invoiceInfo",
        alias: ["invoiceInfo/:id?/:listType?/:childTitle?", ""],
        component: () => import("@oa/views/oa_invoice/invoiceInfo.vue"),
        meta: {
          title: "开票信息",
          icon: "iconfont el-icon-menu-my-invoice",
          hidden: true,
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },
      {
        name: "/myOAInvoice",
        path: "myOAInvoice",
        component: () => import("@oa/views/oa_invoice/myOAInvoice.vue"),
        meta: {
          title: "我的开票",
          icon: "iconfont el-icon-menu-my-invoice",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },
      {
        name: "invoiceList",
        path: "invoiceFinanceList",
        component: () => import("@oa/views/oa_invoice/myOAInvoice.vue"),
        meta: {
          title: "财务开票",
          icon: "iconfont el-icon-menu-my-invoice",
          role: "finance",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      }
    ]
  },

  {
    name: "workflow_manage",
    path: "/approval",
    component: Layouts,
    meta: {
      title: "流程管理",
      icon: "iconfont el-icon-workflow-management",
      alwaysShow: true
    },

    children: [
      {
        name: "workflowDesign",
        path: "workflow/workflowTemplateManagement",
        component: () => import("@oa/views/workflow/workflowTemplateManagement.vue"),
        meta: {
          title: "流程设计",
          icon: "iconfont el-icon-workflow-design",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },
      {
        name: "approvalList",
        path: "approval/approvalList",
        component: () => import("@oa/views/approval/approvalList.vue"),
        meta: {
          title: "流程审批",
          icon: "iconfont el-icon-workflow-approval",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: false
        }
      },
      // {
      //   name: "approvalList",
      //   path: "approval/list",
      //   component: () => import("@oa/views/approval/list.vue"),
      //   meta: {
      //     title: "流程审批",
      //     icon: "iconfont el-icon-workflow-approval"
      //   }
      // },
      {
        name: "approvalInfo",
        path: "approval/approvalInfo",
        alias: ["approval/approvalInfo/:id?", ""],
        component: () => import("@oa/views/approval/approvalInfo.vue"),
        meta: {
          title: "审批信息",
          icon: "iconfont el-icon-workflow-approval",
          hidden: true,
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      }
    ]
  },

  {
    name: "Base_manage",
    path: "/base_manage",
    component: Layouts,
    redirect: "/base_manage/commodityVehicleBrandList",
    meta: {
      title: "基础数据维护",
      icon: "iconfont el-icon-base-data-management",
      alwaysShow: true
    },

    children: [
      {
        name: "advertsManage",
        path: "base_manage/advertsManage",
        component: () => import("@oa/views/base_manage/advertsManage.vue"),
        meta: {
          title: "公告管理",
          icon: "iconfont el-icon-notice-management",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },

      {
        name: "informationManage",
        path: "base_manage/informationManage",
        component: () => import("@oa/views/base_manage/informationManage.vue"),
        meta: {
          title: "企业信息维护",
          icon: "iconfont el-icon-enterprise-info",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },
      {
        name: "baseInfoList",
        path: "base_manage/baseInfoList",
        component: () => import("@oa/views/base_manage/baseInfoList.vue"),
        meta: {
          title: "字典表维护",
          icon: "iconfont el-icon-dictionary-maintenance",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },
      {
        name: "generalsettingsList",
        path: "base_manage/generalsettingsList",
        component: () => import("@oa/views/base_manage/generalsettingsList.vue"),
        meta: {
          title: "参数维护",
          icon: "iconfont el-icon-parameter-management",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },

      {
        name: "userList",
        path: "base_manage/userList",
        component: () => import("@oa/views/base_manage/userList.vue"),
        meta: {
          title: "小程序用户管理",
          icon: "iconfont wechat-user-management",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      }
    ]
  },

  {
    name: "Acl",
    path: "/acl",
    component: Layouts,
    redirect: "/acl/user/list",
    meta: {
      id: "F09",
      title: "权限管理",
      icon: "iconfont el-icon-permission-management",
      alwaysShow: true
    },

    children: [
      {
        name: "User",
        path: "user/list",
        component: () => import("@oa/views/acl/user/list.vue"),
        meta: {
          id: "F090101",
          title: "用户管理",
          icon: "iconfont el-icon-user-list",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },
      {
        name: "Role",
        path: "role/list",
        component: () => import("@oa/views/acl/role/list.vue"),
        meta: {
          id: "F090102",
          title: "角色管理",
          icon: "iconfont el-icon-role-management",
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      },

      {
        name: "RoleAuth",
        path: "role/auth",
        alias: ["role/auth/:id?", ""],
        component: () => import("@oa/views/acl/role/roleAuth.vue"),
        meta: {
          id: "F090103",
          activeMenu: "/acl/role/list",
          title: "角色授权",
          hidden: true,
          // 是否缓存该路由页面
          // 默认为 false，为 true 时代表需要缓存，此时该路由和该页面都需要设置一致的 Name
          keepAlive: true
        }
      }
    ]
  }
]

const router = createRouter({
  history,
  routes: routeSettings.thirdLevelRouteCache ? flatMultiLevelRoutes(constantRoutes) : constantRoutes
})

/** 重置路由 */
export function resetRouter() {
  // 注意：所有动态路由路由必须带有 Name 属性，否则可能会不能完全重置干净
  try {
    router.getRoutes().forEach((route) => {
      const { name, meta } = route
      if (name && meta.roles?.length) {
        router.hasRoute(name) && router.removeRoute(name)
      }
    })
  } catch {
    // 强制刷新浏览器也行，只是交互体验不是很好
    window.location.reload()
  }
}

export default router
