<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount } from "vue"
import { useTheme } from "@/hooks/useTheme"
// 将 Element Plus 的语言设置为中文
import zhCn from "element-plus/es/locale/lang/zh-cn"

import { useRoute } from "vue-router"
const route = useRoute()

const { initTheme } = useTheme()

/** 初始化主题 */
initTheme()

const size = ref("default")
// 判断phone端还是pc端
function _isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}
onMounted(() => {
  changeSize()
  window.addEventListener("resize", changeSize)
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", changeSize)
})

function changeSize() {
  if (window.innerWidth <= 1441) {
    size.value = "small"
  } else {
    size.value = "default"
  }
}
// 'large' | 'default' | 'small'
</script>

<template>
  <el-config-provider :locale="zhCn" :size="size">
    <router-view />
  </el-config-provider>
</template>
