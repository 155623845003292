// 引入axios（axios进行二次封装）
import { request } from "@/utils/service"
import { FunctionInfo, MenuInfo } from "@/api/menu/types/MenuIInfo"

const api_name = "/system/menu"

// 对外暴露获取用户菜单
export function getUserMenus(user_id, platform = "Web") {
  return request<ApiResponseData<FunctionInfo[]>>({
    // url: '/admin/acl/index/info',
    url: `${api_name}/getMenusByUserID`,
    method: "post",
    params: {
      userID: user_id,
      platform: platform
    }
  })
}

/**
 * 获取用户常用功能
 * @param user_id 用户id
 * @param platform 平台
 */
export function getFunctionCommonUseByUserID(user_id, platform) {
  return request<ApiResponseData<FunctionInfo[]>>({
    url: `${api_name}/getFunctionCommonUseByUserID`,
    method: "post",
    params: {
      userID: user_id,
      platform: platform
    }
  })
}

export function saveFunctionCommonUse(userID, functionID) {
  return request<ApiResponseData<number>>({
    url: `${api_name}/saveFunctionCommonUse`,
    method: "post",
    data: {
      userID: userID,
      functionId: functionID
    }
  })
}
