import { request } from "@/utils/service"
import type * as Login from "./types/login"
import { LoginResponseData } from "@/app/oa/api/login/types/login"

/** 获取登录验证码 */
export function getLoginCodeApi() {
  return request<Login.LoginCodeResponseData>({
    url: "login/code",
    method: "get"
  })
}

/** 登录并返回 Token */
export function loginApi_old(data: Login.LoginRequestData) {
  return request<Login.LoginResponseData>({
    url: "users/login",
    method: "post",
    data
  })
}
// 对外暴露登录接口函数
export function loginApi(data: Login.LoginRequestData) {
  const r = request<Login.LoginResponseData>({
    url: "/system/auth/login",
    method: "post",
    data: {
      isSystemUser: true,
      openid: "",
      phone: "",
      push_token: "",
      pwd: data.password,
      source: data.platform,
      userid: data.loginUserID,
      verificationCode: ""
    }
  })
  return r
}

/** 获取用户详情 */
export function getUserInfoApi(userID: string) {
  return request<Login.UserInfoResponseData>({
    url: "/system/user/getUserByUserID",
    method: "post",
    params: {
      userid: userID
    }
  })
}
