import { ref } from "vue"
import store from "@/app/oa/store"
import { defineStore } from "pinia"
import { useTagsViewStore } from "@/store/modules/tags-view"
import { useSettingsStore } from "@/store/modules/settings"
//import { getToken, removeToken, setToken } from "@/utils/cache/cookies"

import {
  getToken,
  removeToken,
  setToken,
  setUserID,
  removeUserID,
  setUserName,
  setUserAvatar,
  getAvatar,
  removeUserName,
  removeUserAvatar
} from "@/utils/auth"

import { resetRouter } from "@/app/oa/router"
import { loginApi, getUserInfoApi } from "@/api/login"
import { type LoginRequestData } from "@/api/login/types/login"
import { MenuInfo } from "@/api/menu/types/MenuIInfo"
import { getUserMenus } from "@/api/menu"
import * as userApi from "@/api/acl/user"

export const useUserStore = defineStore("user", () => {
  const token = ref<string>(getToken() || "")
  const roles = ref<string[]>([])
  const username = ref<string>("")

  const userID = ref<string>("")
  const avatar = ref<string>("")

  const menus = ref<MenuInfo[]>()

  const tagsViewStore = useTagsViewStore()
  const settingsStore = useSettingsStore()

  /** 登录 */
  const login = async ({ loginUserID, password, platform }: LoginRequestData) => {
    // @ts-ignore
    const { data } = await loginApi({ loginUserID, password, platform })
    setUserID(loginUserID)
    setUserName(data.adminInfo ? data.adminInfo.fullName : "")
    // setUserAvatar("data:image/jpeg;base64," + data.adminInfo.faceThumbnailPhoto)
    setToken(data.token)
    // avatar.value = "data:image/jpeg;base64," + res.data.adminInfo.faceThumbnailPhoto
    token.value = data.token
  }
  /** 获取用户详情 */
  const getInfo = async (UserID: string) => {
    const { data } = await getUserInfoApi(UserID)
    //todo:获取角色信息
    userID.value = data.userID
    username.value = data.fullName
    avatar.value = "data:image/jpeg;base64," + data.faceThumbnailPhoto

    setUserAvatar(avatar.value)
    // 验证返回的 roles 是否为一个非空数组，否则塞入一个没有任何作用的默认角色，防止路由守卫逻辑进入无限循环
    // roles.value = data.roleNames?.length > 0 ? [data.roleNames] : routeSettings.defaultRoles
  }

  /**
   * 获取用户菜单
   * @param UserID
   */
  const getMenus = async (UserID: string) => {
    const result = await getUserMenus(UserID)

    let data = result.data
    // let flatten=(data,prev={})=>{
    //   return data.reduce((pre,cur,idx)=>{
    //     let obj=prev.children?{rowSpan: prev.children.length}:{rowSpan: 0}//idx==0&&
    //     return pre.concat(Array.isArray(cur.children)?flatten(cur.children,cur):{...cur,...obj })
    //   },[])
    // }
    const returnedItems = []
    const flattenChilren = function (pid, arr) {
      let nodes = []
      const currentArr = arr && arr.length > 0 ? arr.filter((f) => f.parentFunctionID == pid) : []
      if (currentArr && currentArr.length > 0) {
        currentArr.forEach((item) => {
          const node = {
            functionId: item.functionId,
            functionName: item.functionName,
            parentFunctionID: item.parentFunctionID,
            navigationController: item.navigationController,
            outlookName: item.outlookName,
            platformIconID: item.platformIconID,
            menuName: item.menuName,
            menuComponent: item.menuComponent,
            alwaysShow: item.alwaysShow,
            keepAlive: item.keepAlive,
            hidden: item.hidden,
            listType: item.listType,
            menuOtherMeta: item.menuOtherMeta,
            alias: item.alias
          }
          if (item.children && item.children.length > 0) {
            // @ts-ignore
            node.children = flattenChilren(item.functionId, item.children)
          }
          nodes.push(node)
        })
      }

      return nodes
    }

    const flatten = function (pid, arr) {
      const currentArr = arr.filter((f) => f.parentFunctionID == pid)
      if (currentArr && currentArr.length > 0) {
        currentArr.forEach((item) => {
          const returnedItem = {
            functionId: item.functionId,
            functionName: item.functionName,
            parentFunctionID: item.parentFunctionID,
            navigationController: item.navigationController,
            outlookName: item.outlookName,
            platformIconID: item.platformIconID,
            menuName: item.menuName,
            menuComponent: item.menuComponent,
            alwaysShow: item.alwaysShow,
            keepAlive: item.keepAlive,
            hidden: item.hidden,
            listType: item.listType,
            menuOtherMeta: item.menuOtherMeta,
            alias: item.alias,
            children: []
            //  children: item.children ? flatten(item.functionId, item.children) : []
          }
          if (item.children) {
            // @ts-ignore
            returnedItem.children.push(...flattenChilren(item.functionId, item.children))
          }
          returnedItems.push(returnedItem)
        })
      }
    }
    flatten("g", data)
    menus.value = returnedItems
  }

  const getRoles = async (userID: string) => {
    const { data } = await userApi.getRoles(userID)
    let roleData = []
    // @ts-ignore
    let assignRoles = data?.assignRoles
    if (assignRoles) {
      for (let i = 0; i < assignRoles.length; i++) {
        roleData.push(assignRoles[i].roleID)
      }
      roles.value = roleData
    } else {
      roles.value = []
    }
  }

  /** 模拟角色变化 */
  const changeRoles = async (role: string) => {
    const newToken = "token-" + role
    token.value = newToken
    setToken(newToken)
    // 用刷新页面代替重新登录
    window.location.reload()
  }
  /** 登出 */
  const logout = () => {
    removeToken()
    removeUserID()
    removeUserName()
    removeUserAvatar()
    token.value = ""
    avatar.value = ""
    menus.value = []
    resetRouter()
    _resetTagsView()
  }
  /** 重置 Token */
  const resetToken = () => {
    removeToken()
    token.value = ""
    avatar.value = ""
    //menus.value = []
  }
  /** 重置 Visited Views 和 Cached Views */
  const _resetTagsView = () => {
    if (!settingsStore.cacheTagsView) {
      tagsViewStore.delAllVisitedViews()
      tagsViewStore.delAllCachedViews()
    }
  }

  return {
    token,
    userID,
    username,
    avatar,
    menus,
    roles,
    login,
    getInfo,
    changeRoles,
    logout,
    resetToken,
    getMenus,
    getRoles
  }
})

/** 在 setup 外使用 */
export function useUserStoreHook() {
  return useUserStore(store)
}
