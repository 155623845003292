import router from "@/app/oa/router/index"
// @ts-ignore
import { useUserStoreHook } from "@oa/store/modules/user"
import { usePermissionStoreHook } from "@/app/oa/store/modules/permission"
import { ElMessage } from "element-plus"
// @ts-ignore
import { setRouteChange } from "@oa/hooks/useRouteListener"
// @ts-ignore
import { useTitle } from "@oa/hooks/useTitle"
//import { getToken } from "@/utils/cache/cookies"

import { getToken, getUserID } from "@/utils/auth"

import routeSettings from "@/config/route"
import isWhiteList from "@/config/white-list"
import NProgress from "nprogress"
import "nprogress/nprogress.css"

const { setTitle } = useTitle()
NProgress.configure({ showSpinner: false })

router.beforeEach(async (to, _from, next) => {
  NProgress.start()
  const userStore = useUserStoreHook()
  const permissionStore = usePermissionStoreHook()
  const token = getToken()

  // 如果没有登陆
  if (!token) {
    // 如果在免登录的白名单中，则直接进入
    if (isWhiteList(to)) return next()
    // 其他没有访问权限的页面将被重定向到登录页面
    return next("/login")
  }

  // 如果已经登录，并准备进入 Login 页面，则重定向到主页
  if (to.path === "/login") {
    return next({ path: "/" })
  }
  // 如果用户已经获得其权限角色
  if (userStore.menus && userStore.menus.length !== 0) {
    routeSettings.dynamic ? permissionStore.setRoutes(userStore.menus) : permissionStore.setAllRoutes()

    // @ts-ignore
    permissionStore.addRoutes.forEach((route) => router.addRoute(route))
    return next()
  }
  // 否则要重新获取权限角色
  try {
    // @ts-ignore
    const userID: string = getUserID()
    await userStore.getInfo(userID)
    // 注意：角色必须是一个数组！ 例如: ["admin"] 或 ["developer", "editor"]
    await userStore.getRoles(userID)

    const roles = userStore.roles
    await userStore.getMenus(userID)
    const menus = userStore.menus

    // 生成可访问的 Routes
    routeSettings.dynamic ? permissionStore.setRoutes(menus) : permissionStore.setAllRoutes()

    // @ts-ignore
    permissionStore.addRoutes.forEach((route) => router.addRoute(route))

    // 将 "有访问权限的动态路由" 添加到 Router 中
    // @ts-ignore

    // 确保添加路由已完成
    // 设置 replace: true, 因此导航将不会留下历史记录
    if (permissionStore.addRoutes && permissionStore.addRoutes.length > 0) {
      // 确保添加路由已完成
      // 设置 replace: true, 因此导航将不会留下历史记录
      next({ ...to, replace: true })
    } else {
      next()
    }
  } catch (err: any) {
    // 过程中发生任何错误，都直接重置 Token，并重定向到登录页面
    userStore.resetToken()
    ElMessage.error(err.message || "路由守卫过程发生错误")
    next("/login")
  }
})

router.afterEach((to) => {
  setRouteChange(to)
  setTitle(to.meta.title)
  NProgress.done()
})
