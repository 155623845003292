// core
import { createApp } from "vue"
// @ts-ignore
import App from "@/app/oa/App.vue"
// @ts-ignore
import router from "@oa/router/index.ts"
import "@oa/router/permission"
// load
import { loadSvg } from "@/icons"
import { loadPlugins } from "@/plugins"
// @ts-ignore
import { loadDirectives } from "@oa/directives"
// css
import "uno.css"
import "normalize.css"

import "element-plus/dist/index.css"
import "element-plus/theme-chalk/dark/css-vars.css"
import "vxe-table/lib/style.css"
import "vxe-table-plugin-element/dist/style.css"
import "@/styles/index.scss"

import "@oa/assets/fonts/iconfont.css"
/**
 * 去除谷歌浏览器的scroll、wheel等事件警告
 */
import "@/app/oa/config/ployfill"

const app = createApp(App)

/** 加载插件 */
loadPlugins(app)
/** 加载全局 SVG */
loadSvg(app)
/** 加载自定义指令 */
loadDirectives(app)

app.use(router)

router.isReady().then(() => {
  app.mount("#app")
})
